import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from '@services/base.service';
import { AuthService } from '@services/auth.service';
import { UrlService } from '@services/url.service';
import { IPrescriber } from '@shared/models/prescriber.model';
import { TenantService } from '@services/tenant.service';
import { Observable } from 'rxjs';

@Injectable()
export class PrescriberService extends BaseService {
  serviceUrl: string;

  mandatoryFields: string[] = ['firstname', 'lastname', 'address', 'city', 'state', 'zip', 'phone'];

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private tenantService: TenantService,
    private urlService: UrlService,
  ) {
    super();

    this.serviceUrl = urlService.PRESCRIBER_URL;
  }

  getPrescriber(npi: string) {
    return this.http.get<IPrescriber>(this.serviceUrl + '/' + npi);
  }

  getPrescribers(): Observable<any> {
    return this.http.post<any>(this.serviceUrl, { tenant: this.tenantService.currentTenant?.id });
  }

  update(prescriber: IPrescriber) {
    this.isLoading.next(true);
    return this.http.put(this.serviceUrl, JSON.stringify(prescriber));
  }

  isPrescriberValid(prescriber: IPrescriber): boolean {
    let isValid = true;

    this.mandatoryFields.forEach(field => {
      if (!(prescriber as Record<string, any>)[field]) {
        isValid = false;
      }
    });

    return isValid;
  }
}
